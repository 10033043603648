import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Navbar from './components/Navbar';
import GenericNotFound from './components/GenericNotFound';

import Context from './Context';
import Footer from './components/Footer';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routerRef = React.createRef();
  }

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state
        }}
      >
        <Router ref={this.routerRef}>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <RouteWithNavbar component={GenericNotFound} />
          </Switch>
        </Router>
      </Context.Provider>
    );
  }
}

const RouteWithNavbar = ({ exact, path, component: Component, ...rest }) => {
  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(routeProps) => {
        return (
          <div className="App is-fullhd">
            <Navbar />
            <Component {...routeProps} />
            <Footer />
          </div>
        );
      }}
    />
  );
};
