import React from 'react';

export default function Footer() {
  return (
    <div className="footer">
      <b className="footer-logo">Tracker Elite</b>
      <div className="footer-info">
        <div className="info-details">
          <b>Company</b>
          <ul>
            <li>About</li>
            <li>Team</li>
            <li>Careers</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
        <div className="info-details">
          <b>Social Media</b>
          <ul>
            <li>Facebook</li>
            <li>Twitter</li>
            <li>Instagram</li>
            <li>Youtube</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
