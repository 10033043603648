import React from 'react';

export default function Overview() {
  return (
    <div>
      <div className="columns cards-container is-1-mobile is-3-desktop is-2-tablet">
        <div className="column landing-column">
          <div className="card">
            <div className="card-content">
              <div className="media">
                <span className="icon is-large">
                  <i className="fas fa-2x far fa-file-alt"></i>
                </span>
              </div>
              <div className="content">
                <p className="is-size-4 has-text-weight-bold">What We Do</p>
                <p className="is-6">
                  Tracker Elite is a medium for analyst, traders, media and news agencies, and market researchers to
                  track and explore companies through data driven investment & insights via our web interface.
                </p>
                <button className="learn-more hybrid-color-text">Learn More</button>
              </div>
            </div>
          </div>
        </div>
        <div className="column landing-column">
          <div className="card">
            <div className="card-content">
              <div className="media">
                <span className="icon is-large">
                  <i className="fas fa-2x far fa-folder-open"></i>
                </span>
              </div>
              <div className="content">
                <p className="is-size-4 has-text-weight-bold">Our Data</p>
                <p className="is-6">
                  Access nearly a dozen datasets per company that’s continually growing and expanding.
                </p>
                <button className="learn-more hybrid-color-text">Learn More</button>
              </div>
            </div>
          </div>
        </div>
        <div className="column landing-column">
          <div className="card">
            <div className="card-content">
              <div className="media">
                <span className="icon is-large">
                  <i className="fas fa-2x fa-search"></i>
                </span>
              </div>
              <div className="content">
                <p className="is-size-4 has-text-weight-bold">Access</p>
                <p className="is-6">
                  Our datasets includes information on Data, B2B Rights, Brand, Relationships, ESG etc that can be protected by
                  Intellectual Property and that drives stake holder Value.
                </p>
                <button className="learn-more hybrid-color-text">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="level is-mobile landing-level">
        <div className="level-item has-text-centered">
          <div>
            <p className="data-number hybrid-color-text">12+</p>
            <p className="hybrid-color-text is-lowercase">Datasets</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="data-number hybrid-color-text">100+</p>
            <p className="hybrid-color-text is-lowercase">Companies</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="data-number hybrid-color-text">25+</p>
            <p className="hybrid-color-text is-lowercase">Connections of Unreported Data</p>
          </div>
        </div>
      </nav>
    </div>
  );
}
