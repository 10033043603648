import axios from 'axios';
import React, { useState } from 'react';

export default function Start() {
  const [otherChosen, setSelection] = useState(false);
  const [info, setInfo] = useState({ type: 'Retail Investor' });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const userTypes = [
    'Retail Investor',
    'Accountant',
    'Finance',
    'CFO',
    'News and Media Agency',
    'Market Researcher',
    'Other'
  ];

  const selectionHandler = (e) => {
    const chosen = e.target.value === 'Other';
    setSelection(chosen);
    if (!chosen) {
      setInfo({ ...info, type: e.target.value });
    }
    setError(null);
    setSuccess(null);
  };

  const changeHandler = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
    setError(null);
    setSuccess(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!info.name || info.name.trim() === '') {
      setError('Please enter your name.');
      return;
    }
    if (!info.type || info.type.trim() === '') {
      setError('Please enter type.');
      return;
    }
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(info.email)) {
      setError('Please enter a valid email.');
      return;
    }
    subscribe();
  };

  const subscribe = () => {
    axios
      .get("https://subscribe.trackerelite.io/subscribe?type=" + info.type + "&name=" + info.name + "&email=" + info.email)
      .then((result) => {
        setError(null);
        setSuccess('You have successfully subscribed to our list!');
        document.getElementById('form').reset();
      })
      .catch((error) => setError('There is an error.'));
  };

  return (
    <div className="start">
      <div className="description">
        <b className="landing-title">Democratized Access of Alternative Data</b>
        <br />
        <p className="landing-subtitle">
          We make Alternative Data accessible to everyone via our web interface to empower the next generation of investing.
        </p>
      </div>
      <div className="waiting-list-box">
        <b className="waiting-list-title">Gain Access to our Waiting List</b>
        <form className="form" onSubmit={handleSubmit} id="form">
          <label>
            <b>Name</b>
            <input type="text" name="name" placeholder="Name" onChange={changeHandler} />
          </label>
          <label>
            <b>Email Address</b>
            <input type="text" name="email" placeholder="alex@example.com" onChange={changeHandler} />
          </label>
          <label>
            <b>Type</b>
            <div className="select is-fullwidth">
              <select onChange={selectionHandler}>
                {userTypes.map((type, i) => (
                  <option key={i}>{type}</option>
                ))}
              </select>
            </div>
          </label>
          {otherChosen && (
            <input className="input" type="text" placeholder="Other Type" name="type" onChange={changeHandler} />
          )}
          <input className="submit" type="submit" value="Submit" />
        </form>
        {error && <div className="error">{error}</div>}
        {success && <div className="success">{success}</div>}
      </div>
    </div>
  );
}
