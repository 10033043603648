import React from 'react';
import Navbar from './Navbar';
import Start from './Start';
import Overview from './Overview';
import Footer from './Footer';
import Features from './Features';

export default function LandingPage() {
  return (
    <div className="App">
      <div className="introduction">
        <Navbar />
        <Start />
      </div>
      <div className="section">
        <Overview />
      </div>
      <div className="section landing-info-section">
        <Features />
      </div>
      {/* <div className="section landing-video">
        <figure className="image is-16by9">
          <iframe
            title="sample-video"
            className="has-ratio"
            src="https://www.youtube.com/embed/KFmA9W8i4X4"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </figure>
      </div> */}
      <Footer />
    </div>
  );
}
