import React from 'react';

export default function GenericNotFound() {
  return (
    <div className="card notfound has-text-centered">
      <div className="card-content">
        <div className="content">
          <p className="is-size-1">404 Sorry page doesn't exist</p>
        </div>
      </div>
    </div>
  );
}
