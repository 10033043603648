import React from 'react';
import analytic from '../img/NewBlogFeature-Breaking-Down-Web-Analytics-Referrer-Type.png';
import business from '../img/business-clipart-transparent-background-5.png';
import customer from '../img/customer-service-skills.one-half.png';

export default function Features() {
  return (
    <div className="columns is-multiline is-vcentered">
      <div className="column is-half">
        <p className="title">Alternative Data Drives Stakeholder Value and Market Disruption</p>
        <p className="subtitle has-text-grey">
          Over a span of forty years, more than 80% of the average valuation of companies on the S&#38;P 500 was
          represented by tangible assets.
          <br />
          <br />
          This number is now reversed with more than 80% of an organization's attributed value represented by
          intangibles.
        </p>
      </div>
      <div className="column is-half">
        <figure className="image">
          <img src={analytic} alt="analytic sample" />
        </figure>
      </div>
      <div className="column business is-half">
        <figure className="image">
          <img src={business} alt="business sample" />
        </figure>
      </div>
      <div className="column is-half">
        <p className="title">Datasets Per Company</p>
        <p className="subtitle has-text-grey">
          Tracker Elite identified more than 12 datasets.
          <br />
          These datasets are underdeveloped and underreported intangible assets and insights in companies.
        </p>
      </div>
      <div className="column business-mobile is-half">
        <figure className="image">
          <img src={business} alt="business sample" />
        </figure>
      </div>
      <div className="column is-half">
        <p className="title">Actionable Insights</p>
        <p className="subtitle has-text-grey">
          Using our extensive platform you can cut out your prospecting and tedious work with narrowing down new
          investments, identifying upcoming market trends and staying informed while getting back to the things that matter.
        </p>
      </div>
      <div className="column is-half">
        <figure className="image">
          <img src={customer} alt="customer sample" />
        </figure>
      </div>
    </div>
  );
}
